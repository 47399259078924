/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

function validateZipcode( zipcode ) {
    var regex = /^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i;
    return regex.test( zipcode );
}

/**
 * Configurator user data
 */
var userTypeHouse = false;
var userIntroductionPrice;
var extraOptions = [];
var userPostcode;
var userHouseNumber;
var userPostcodenlData;
var userComments;
var userDate;
var userTime;
var step2valid = false;
var step4valid = false;

(function ( $ ) {

    $.datepicker.setDefaults( $.datepicker.regional.nl );
    $( '.tooltip' ).tooltipster( {
        delay: 0,
    } );

    var gwoConfContainer = $( "#gwoConfigurator" );
    var gwoConf;
    var gwoConfContainerSettings = {
        title: "HTML code",
        content: "<strong>HTML code</strong>",
        transitionEffect: "fade",
        enableFinishButton: false,
        labels: {
            cancel: "Cancel",
            current: "current step:",
            pagination: "Pagination",
            next: "Volgende stap",
            previous: "Vorige stap",
            loading: "Loading ..."
        },
        onStepChanging: function ( event, currentIndex, newIndex ) {

            /**
             * Specific action for the steps
             */
            // // console.log( '############## onStepChanging ##############' );
            // // console.log( 'onStepChanging - currentIndex: ' + currentIndex );
            // // console.log( 'onStepChanging - newIndex: ' + newIndex );

            if ( currentIndex === 0 && userTypeHouse === false ) {
                return false;
            }

            if ( newIndex === 2 && step2valid !== true ) {
                return false;
            }

            if ( newIndex === 4 && step4valid !== true ) {
                return false;
            }

            if ( newIndex === 3 ) {
              var optionRule = {};
              extraOptions = [];
              $("input:checkbox[name=extra_option]:checked").each(function() {
                var $checkbox = $(this);
                var optionName = $checkbox.val();
                var optionPrice = parseFloat($checkbox.attr('data-option-price'));
                var originalPrice = parseFloat($checkbox.attr('data-original-price'));

                var $quantityInput = $checkbox.siblings('.js-extra-option-quantity');
                var optionQuantity;

                if ($quantityInput.length > 0) {

                    if (!isNaN(originalPrice) && !isNaN(optionPrice)) {
                        optionQuantity = optionPrice / originalPrice;
                    } else {
                        optionQuantity = 1;
                    }
                } else {
                    optionQuantity = 1;
                }

                optionRule = {
                    optionName: optionName,
                    optionPrice: optionPrice,
                    optionQuantity: optionQuantity
                };

                extraOptions.push(optionRule);
              });

            }

            if ( newIndex === 4 ) {

              var dateToday = new Date();

                userDate = $( "#datepicker" ).datepicker( {
                    dateFormat: 'dd,MM,yyyy'
                } ).val();

                userTime = $( 'input[name=conf_time]:checked' ).val();

                var addressLabel = userPostcodenlData.address.street;
                addressLabel += ' ' + userPostcodenlData.address.houseNumber;
                addressLabel += '<br>' + userPostcodenlData.address.postcode;
                addressLabel += ' ' + userPostcodenlData.address.city + ' ';


                $( 'span#userOptions' ).html('');
                var index;
                var optionsTotalPrice = 0;
                for (index = 0; index < extraOptions.length; ++index) {
                  // console.log(extraOptions[index][optionName]);

                  optionsTotalPrice = optionsTotalPrice + parseFloat(extraOptions[index].optionPrice);
                  $( 'span#userOptions' ).append('<br /><span>'+ extraOptions[index].optionName +'(€' + extraOptions[index].optionPrice +')</span>');
                }

                var btw;

                console.log("totaal optie prijs" + optionsTotalPrice);
                console.log("user intro prijs" + userIntroductionPrice);

                if(extraOptions.length !== 0){
                  totalPrice = parseFloat(optionsTotalPrice) + parseFloat(userIntroductionPrice);
                  btw = (totalPrice / 121 * 21).toFixed( 2 ).replace( ".", "," );
                } else {
                  totalPrice = userIntroductionPrice;
                  btw = (userIntroductionPrice / 121 * 21).toFixed( 2 ).replace( ".", "," );
                }

                $( 'span#userTypeHouse' ).text( userTypeHouse.name );
                $( 'span#userAddress' ).html( addressLabel );
                $( 'span#userDate' ).html( userDate );
                $( 'span#userTime' ).html( userTime );
                if ( typeof totalPrice != 'number' ) {
                    totalPrice = parseFloat(totalPrice)
                }
                $( 'span#userPrice' ).html( '&euro;' + totalPrice.toFixed( 2 ).replace( ".", "," ) + ' (incl. &euro;' + btw + ' btw)' );

              // Object.keys(extraOptions).forEach(function(key) {
              //   console.log(key.optionName);
              //   $( 'span#userOptions' ).html( key[optionName] );
              // });


              //console.log('j');

            }

            if ( currentIndex > newIndex ) {
                return true;
            }

            return true;
        },
        onStepChanged: function ( event, currentIndex, priorIndex ) {

            // // console.log( '############## onStepChanged ##############' );
            // // console.log( 'onStepChanged - currentIndex: ' + currentIndex );
            // // console.log( 'onStepChanged - priorIndex: ' + priorIndex );

            // /**
            //  * Step 2 - Uw adres
            //  */
            // if ( currentIndex === 1 ) {
            //     // console.log( 'step2valid: ' + step2valid );
            // }
            //
            // /**
            //  * Step 3 - Datum & tijd
            //  */
            // if ( currentIndex === 2 ) {
            //
            //
            // }

            return true;
        }
    };


    if ( gwoConfContainer.length ) {
        gwoConf = gwoConfContainer.steps( gwoConfContainerSettings );
    }

    $( '#adresCheckForm' ).on( 'submit', function ( e ) {
        e.preventDefault();

        //var data = $( '#adresCheckForm' ).serialize();
        $( '#adresCheckFormhouseNumber' ).trigger( 'keyup' );

        return false;
    } );

    $( '.go-to-url' ).on( "click", function () {
        window.open( $( this ).data( 'url' ), "_self" );
    } );

    function blocked_days_function( date ) {
        var string = jQuery.datepicker.formatDate( 'yy-mm-dd', date );
        return [blocked_days.indexOf( string ) === -1];
    }

    function noWeekendsOrHolidays( date ) {
        var noWeekend = $.datepicker.noWeekends( date );
        if ( noWeekend[0] ) {
            return blocked_days_function( date );
        } else {
            return noWeekend;
        }
    }

    function getUrlVars() {
        var vars = [], hash;
        var hashes = window.location.href.slice( window.location.href.indexOf( '?' ) + 1 ).split( '&' );
        for ( var i = 0; i < hashes.length; i++ ) {
            hash = hashes[i].split( '=' );
            vars.push( hash[0] );
            vars[hash[0]] = hash[1];
        }
        return vars;
    }

    function base64UrlDecode(input) {
        input = input.replace(/-/g, '+').replace(/_/g, '/').replace(/,/g, '=');
        return atob(input);
    }

    /**
     * Disabled function for buttons
     */
    jQuery.fn.extend( {
        disable: function ( state ) {
            return this.each( function () {
                this.disabled = state;
            } );
        }
    } );

    var Sage = {
        // All pages
        'common': {
            init: function () {

                // JavaScript to be fired on all pages
                $( '.hero-slider' ).slick( {
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 4000
                } );

                $( '#toggleMobileMenu' ).click( function () {
                    $( '.mobile-menu' ).toggleClass( 'active' );
                } );


                $( '#my-account-tabs li a' ).click( function () {

                    var dataClass = $( this ).data( 'toggle' );

                    if ( ! dataClass ) {
                      console.log('are we here?')
                      return true;
                    }

                    $( '#my-account-tabs li a' ).removeClass( 'active' );
                    $( this ).addClass( 'active' );
                   // // console.log( dataClass );
                    $( '.tab-data' ).removeClass( 'active' );
                    $( '#' + dataClass ).addClass( 'active' );

                    return false;
                } );


                $( "#tabs" ).tabs();


                if ( $( '#isLoggedIn' ).length ) {
                    if ( $( '#isLoggedIn' ).val() === '1' ) {
                        step4valid = true;
                    }
                }

                if ( gwoConfContainer.length ) {

                    /**
                     * Step 1 - Type woning select
                     */
                    var typeHouseItem = gwoConfContainer.find( '.type-house-list li a' );
                    typeHouseItem.on( 'change', function () {
                        typeHouseItem.removeClass( 'active' );
                        $( this ).addClass( 'active' );

                        $( this ).find( 'input' ).prop( 'checked', true );

                        userTypeHouse = jQuery.parseJSON( $( 'input[name=typeHouse]:checked' ).val() );
                        userIntroductionPrice = $( 'input[name=typeHouse]:checked' ).data( 'price' );

                        // Show extra options related to this type

                        //
                        $('.option-row').hide();
                        $('[data-housetype=' + userTypeHouse.term_id +']').show();

                        gwoConf.steps( 'next' );

                        return false;
                    } );

                    var url_vars = getUrlVars();
                    var url_type = url_vars.type;

                    if ( typeof url_type !== "undefined" ) {
                        gwoConfContainer.find( 'input#' + url_type ).closest( 'a' ).trigger( 'change' );

                        $( 'html, body' ).animate( {
                            scrollTop: $( "#gwoConfigurator" ).offset().top
                        }, 500 );
                    }

                    /**
                     * Step 2 - Adres
                     */

                    var timer;
                    gwoConfContainer.on( 'keyup', '#adresCheckFormPostcode, #adresCheckFormhouseNumber', function () {
                        var adresCheckFormPostcode = $( '#adresCheckFormPostcode' );
                        var adresCheckFormhouseNumber = $( '#adresCheckFormhouseNumber' );

                        step2valid = false;

                        if ( validateZipcode( adresCheckFormPostcode.val() ) && adresCheckFormhouseNumber.val() !== '' ) {

                            // // console.log( 'adresCheckFormPostcode: ' + adresCheckFormPostcode.val() );
                            // // console.log( 'adresCheckFormhouseNumber: ' + adresCheckFormhouseNumber.val() );

                            $( '#adresCheckLoader' ).fadeIn();
                            clearTimeout( timer );
                            timer = setTimeout( function () {

                                $.ajax( {
                                    url: gwo.ajax_url,
                                    type: 'get',
                                    dataType: 'json',
                                    data: {
                                        action: 'postcode_get_address',
                                        postcode: adresCheckFormPostcode.val(),
                                        house_number: adresCheckFormhouseNumber.val(),
                                    },
                                    beforeSend: function () {
                                    },
                                    success: function ( response ) {
                                      $( '#adresCheckLoader' ).hide();

                                        var infoWindowText = '<div class="googleInfoWindow" style="padding: 20px;"><strong>Uw gekozen adres:</strong><br /><br />';
                                        infoWindowText += '<span class="street_house_number">' + response.address.street + ' ';
                                        infoWindowText += response.address.houseNumber + ' ' + response.address.houseNumberAddition + '</span>';
                                        infoWindowText += '<br><span class="postcode_city">' + response.address.postcode + ' ' + response.address.city + '</span>';

                                        if ( response.price !== false ) {
                                            userIntroductionPrice = parseFloat( response.price );
                                            infoWindowText += '<br><span class="price">Reguliere prijs: &euro;' + response.price + '</span>';
                                        }

                                        infoWindowText += '</div>';

                                        if ( typeof response.status !== "undefined" ) {
                                            alert( 'Geen geldig adres' );
                                            return false;
                                        }

                                        if ( response.postcode_check === true ) {
                                            $( '#adresCheckFormOutRange' ).hide();
                                        } else {
                                            $( '#adresCheckFormOutRange' ).show();
                                        }

                                        if ( response.address !== false ) {
                                            var bound = new google.maps.LatLngBounds();

                                            var map = new google.maps.Map( document.getElementById( 'adresCheckMap' ), {
                                                zoom: 4,
                                                maxZoom: 17
                                            } );

                                            var infowindow = new google.maps.InfoWindow( {
                                                content: infoWindowText
                                            } );

                                            var marker = new google.maps.Marker( {
                                                position: {
                                                    lat: response.address.latitude,
                                                    lng: response.address.longitude
                                                },
                                                map: map,
                                                animation: google.maps.Animation.DROP
                                            } );

                                            setTimeout( function () {
                                                infowindow.open( map, marker );
                                            }, 1000 );

                                            bound.extend( marker.getPosition() );
                                            map.fitBounds( bound );

                                            $( '#adresCheckMap' ).fadeIn( 300 );

                                            userPostcode = response.address.postcode;
                                            userHouseNumber = response.address.houseNumber;
                                            userPostcodenlData = response;
                                            step2valid = true;
                                        }
                                    }
                                } );
                            }, 1500 );
                        } else {
                            $( '#adresCheckMap' ).slideUp();
                        }
                    } );

                    /**
                     * Book Again Button
                     */
                    if ( url_vars.includes('typeHouse') && url_vars.includes('postcode') && url_vars.includes('houseNumber') && url_vars.includes('initialPrice') ) {

                      // Decode passed params
                      if (url_vars.typeHouse) url_vars.typeHouse = base64UrlDecode(url_vars.typeHouse);
                      if (url_vars.postcode) url_vars.postcode = base64UrlDecode(url_vars.postcode);
                      if (url_vars.houseNumber) url_vars.houseNumber = base64UrlDecode(url_vars.houseNumber);
                      if (url_vars.initialPrice) url_vars.initialPrice = base64UrlDecode(url_vars.initialPrice);
                      if (url_vars.extraOptions) url_vars.extraOptions = JSON.parse(base64UrlDecode(url_vars.extraOptions));

                      if (userTypeHouse === false) {
                          userTypeHouse = {
                              term_id: url_vars.typeHouse
                          };
                      }
                      userIntroductionPrice = url_vars.initialPrice;

                      var adresCheckFormPostcode = url_vars.postcode;
                      var adresCheckFormhouseNumber = url_vars.houseNumber;

                      gwoConf.steps( 'next' )

                      if ( validateZipcode(adresCheckFormPostcode) && adresCheckFormhouseNumber !== '' ) {

                        $.ajax( {
                          url: gwo.ajax_url,
                          type: 'get',
                          dataType: 'json',
                          data: {
                            action: 'postcode_get_address',
                            postcode: adresCheckFormPostcode,
                            house_number: adresCheckFormhouseNumber,
                          },
                          beforeSend: function () {
                          },
                          success: function ( response ) {
                            if ( typeof response.status !== "undefined" ) {
                              alert( 'Geen geldig adres' );
                              return false;
                            }

                            if ( response.address !== false ) {
                              userPostcode = response.address.postcode;
                              userHouseNumber = response.address.houseNumber;
                              userPostcodenlData = response;
                              step2valid = true;
                            }
                          },
                          complete: function() {
                            gwoConf.steps( 'next' )
                          }
                        } );
                      }

                      $('[data-housetype=' + userTypeHouse.term_id +']').show();

                      // Set Extra optionss
                      if (url_vars.extraOptions.length) {
                        url_vars.extraOptions.forEach(function(option) {
                          var $extraOptionParentElement = $('.option-row[data-housetype="' + option.type_woning + '"][data-option-name="' + option.naam + '"]');
                          var $extraOptionElement = $extraOptionParentElement.find('input[type="checkbox"]');
                          var $extraOptionQuantityElement = $extraOptionElement.siblings('.js-extra-option-quantity')

                          $extraOptionElement.attr('data-option-price', option.prijs)

                          $extraOptionParentElement.attr('data-price', option.prijs)
                          $extraOptionElement.prop('checked', true)

                          var displayPrice = option.prijs;

                          if ($extraOptionQuantityElement.length) {
                            displayPrice = option.prijs / option.quantity;
                            $extraOptionQuantityElement.val(option.quantity)
                            $extraOptionElement.attr('data-original-price', displayPrice)
                          }

                          $extraOptionParentElement.find('label i').html('(+€' + displayPrice + ')')
                        });
                      }

                    }

                    /**
                     * Step 3 Datum & tijd
                     */

                    $( "#datepicker" ).datepicker( {
                        minDate: +1,
                        onSelect: function ( dateText, inst ) {
                            // // console.log( 'Date selected:' );
                            // // console.log( dateText );
                            // // console.log( inst );
                        },
                        beforeShowDay: noWeekendsOrHolidays
                    } );
                    //// console.log( $.datepicker.noWeekends );

                    /**
                     * Ajax login form
                     */
                    $( '#gwoLoginForm' ).submit( function ( e ) {
                        e.preventDefault();

                        var user_login = $( this ).find( '#user_login' ).val();
                        var user_password = $( this ).find( '#user_password' ).val();
                        var security = $( this ).find( '#security' ).val();

                        $.ajax( {
                            url: gwo.ajax_url,
                            type: 'POST',
                            dataType: 'json',
                            data: {
                                action: 'gwo_login_form',
                                user_login: user_login,
                                user_password: user_password,
                                security: security
                            },
                            success: function ( response ) {
                                if ( response.loggedin === false ) {
                                    $( '#loginMessage' ).text( response.message );
                                    $( '#loginMessage' ).css( 'opacity', 1 );
                                } else {
                                    $( '#currentUserFullName' ).text( response.fullname );
                                    $( '#currentUserEmail' ).text( response.email );
                                    $( '.step-four__current-user' ).slideToggle();
                                    $( '.step-four__new-user' ).slideToggle();
                                    step4valid = true;
                                }
                            }
                        } );

                        return false;
                    } );

                    /**
                     * Ajax register form
                     */
                    $( '#gwoRegisterForm' ).submit( function ( e ) {
                        e.preventDefault();

                        $.ajax( {
                            url: gwo.ajax_url,
                            type: 'POST',
                            dataType: 'json',
                            data: {
                                action: 'gwo_register_form',
                                data: $( this ).serialize()
                            },
                            success: function ( response ) {

                               // // console.log( response );
                                if ( response.status === 'error' ) {
                                    var registrationMessage = $( '#registrationMessage' );
                                    registrationMessage.addClass( 'error' );
                                    registrationMessage.text( response.message );
                                } else {
                                    step4valid = true;
                                }

                                if ( response.loggedin === false ) {
                                    $( '#loginMessage' ).text( response.message );
                                    $( '#loginMessage' ).css( 'opacity', 1 );
                                } else {
                                    $( '#currentUserFullName' ).text( response.fullname );
                                    $( '#currentUserEmail' ).text( response.email );
                                    $( '.step-four__current-user' ).slideToggle();
                                    $( '.step-four__new-user' ).slideToggle();
                                    step4valid = true;
                                }
                            }
                        } );

                        return false;
                    } );

                    /**
                     * Ajax logout button
                     */
                    $( '#gwoLogoutUser' ).on( 'click', function ( e ) {
                        e.preventDefault();

                        $.ajax( {
                            url: gwo.ajax_url,
                            type: 'GET',
                            dataType: 'json',
                            data: {
                                action: 'gwo_logout_user',
                                data: $( this ).serialize()
                            },
                            success: function ( response ) {
                                if ( response.logout_success === true ) {
                                    $( '.step-four__current-user' ).slideToggle();
                                    $( '.step-four__new-user' ).slideToggle();
                                    step4valid = false;
                                }
                            }
                        } );

                        return false;
                    } );

                    /**
                     * Forgot password
                     */
                    $( '#gwoForgotPasswordForm' ).submit( function ( e ) {
                        e.preventDefault();

                        $.ajax( {
                            url: gwo.ajax_url,
                            type: 'POST',
                            dataType: 'json',
                            data: {
                                action: 'gwo_forgot_password',
                                data: $( this ).serialize(),
                                user_login: $( '#gwoForgotPasswordForm input[name="user_login"]' ).val()
                            },
                            success: function ( response ) {
                                if ( response.status === 'error' ) {
                                    $.each( response.message.errors, function ( index, value ) {
                                        //// console.log( index + ": " + value );
                                        $( '<p>' + value + '</p>' ).appendTo( '#gwoForgotPasswordMessage' );
                                    } );
                                }
                            }
                        } );
                    } );

                    $( '#gwoGoToStep5' ).on( 'click', function ( e ) {
                        e.preventDefault();

                        gwoConf.steps( 'next' );
                        return false;
                    } );

                    /**
                     * Bevestiging boeking
                     */
                    $( '#gwoConfirmOrder' ).on( 'click', function ( e ) {
                        e.preventDefault();

                        userComments = $( '#userComments' ).val();

                        var order_data = {
                            typeHouse: userTypeHouse,
                            introductionPrice: parseFloat(totalPrice),
                            extraOptions: extraOptions,
                            address: userPostcodenlData,
                            comments: userComments,
                            date: userDate,
                            time: userTime
                        };

                        $.ajax( {
                            url: gwo.ajax_url,
                            type: 'POST',
                            dataType: 'json',
                            data: {
                                action: 'gwo_confirm_booking',
                                data: order_data
                            },
                            beforeSend: function ( xhr ) {
                                $( '#gwoConfirmOrder' ).disable( true );
                            },
                            success: function ( response ) {
                              console.log(response)
                                if ( response.booking_created === true ) {
                                    window.location.replace( response.payment._links.checkout.href );
                                }
                            }
                        } );
                    } );

                    $( '#gwoShowForgotPassword' ).on( 'click', function ( e ) {
                        e.preventDefault();
                        $( '#gwoForgotPasswordForm' ).slideToggle();
                    } );
                }
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired

            }
        },
        // Home pag
        'page_template_template_direct_booking': {
            init: function () {
              function blocked_days_function( date ) {
                var string = jQuery.datepicker.formatDate( 'yy-mm-dd', date );
                return [blocked_days.indexOf( string ) === -1];
            }

            function noWeekendsOrHolidays( date ) {
                var noWeekend = $.datepicker.noWeekends( date );
                if ( noWeekend[0] ) {
                    return blocked_days_function( date );
                } else {
                    return noWeekend;
                }
            }


              userDate = $( ".direct-booking__date" ).datepicker( {
                dateFormat: 'yy-mm-dd',
                minDate: +1,
                beforeShowDay: noWeekendsOrHolidays
              } );

                $( '#formConfirmMailOrder' ).submit( function ( e ) {
                    e.preventDefault();

                    var typeHouse = JSON.parse( $( this ).find( '[name="typeHouse"]' ).val() );
                    var address = JSON.parse( $( this ).find( '[name="address"]' ).val() );
                    var price = $( this ).find( '[name="price"]' ).val();
                    var time = $( this ).find( '[name="time"]:checked' ).val();
                    var uid = $( this ).find( '[name="uid"]' ).val();

                    var dateObject = userDate.datepicker( "getDate" );
                    var date = $.datepicker.formatDate( "yy-mm-dd", dateObject );

                    var order_data = {
                        typeHouse: typeHouse,
                        price: price,
                        address: address,
                        date: date,
                        time: time,
                        uid: uid
                    };

                    $.ajax( {
                        url: gwo.ajax_url,
                        type: 'POST',
                        dataType: 'json',
                        data: {
                            action: 'gwo_confirm_mail_booking',
                            data: order_data
                        },
                        success: function ( response ) {
                            if ( response.booking_created === true ) {
                                window.location.replace( response.payment._links.checkout );
                            }
                        }
                    } );

                    return false;
                } );
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS


            }
        },
        // About us page, note the change from about-us to about_us.
        'page_template_template_my_account': {
            init: function () {

                var startdate = $( '#subscription_startdate' ).datepicker( {
                    minDate: +1
                } );

                startdate.datepicker().datepicker( 'setDate', new Date() );

                $( '#myAccountInformation' ).submit( function ( e ) {
                    e.preventDefault();

                    var form_data = $( this ).serialize();

                    $.ajax( {
                        url: gwo.ajax_url,
                        type: 'POST',
                        dataType: 'json',
                        data: {
                            action: 'gwo_myaccount_information',
                            data: form_data,
                            security: gwo.ajax_nonce_myaccount
                        },
                        success: function ( response ) {
                            if ( response.booking_created === true ) {
                                window.location.replace( response.payment._links.checkout );
                            }
                        }
                    } );

                   // // console.log( $( this ).serialize() );
                } );

                $( '#myAccountNewSubscription' ).on( 'change', '#subscription_address, #subscription_frequency, #subscription_startdate', function () {

                    var form_data = $( '#myAccountNewSubscription' ).serializeArray();

                    var address_fields = $( '#subscription_address' ).find( ":checked" ).data( 'fields' );
                    var frequency = $( '#subscription_frequency' ).find( ":checked" ).text();
                    var subscription_summary = $( '.subscription_summary' );

                    if ( address_fields !== '' ) {

                        subscription_summary.find( '.address' ).text( address_fields.street + ' ' + address_fields.houseNumber + address_fields.houseNumberAddition );
                        subscription_summary.find( '.postcode-city' ).text( address_fields.postcode + ' ' + address_fields.city );
                        subscription_summary.find( '.price' ).html( '&euro;' + address_fields.price );
                        subscription_summary.find( '.frequency' ).text( frequency );
                        subscription_summary.find( '.start-date' ).text( $( '#subscription_startdate' ).val() );

                        subscription_summary.show();
                    } else {
                        subscription_summary.hide();
                    }
                } );

                $( '#myAccountNewSubscription' ).submit( function ( e ) {
                    e.preventDefault();

                    var form_data = $( this ).serialize();
                    // console.log( form_data );

                    var submit_btn = $( '.subscription-submit-button' );


                    if ( $( '#check_incasso' ).is( ":checked" ) === false ) {
                        alert( 'U dient akkoord te gaan met het automatisch incasseren van het verschuldigde bedrag.' );
                        return false;
                    }

                    if ( $( '#privacy_policy' ).is( ":checked" ) === false ) {
                        alert( 'U dient akkoord te met onze algemene voorwaarden en privacy policy.' );
                        return false;
                    }

                    $.ajax( {
                        url: gwo.ajax_url,
                        type: 'POST',
                        dataType: 'json',
                        data: {
                            action: 'gwo_new_subscription',
                            data: form_data
                        },
                        beforeSend: function ( xhr ) {
                            submit_btn.html( 'Bezig met verwerken' );
                        },
                        success: function ( response ) {
                            console.log(response);
                            if ( response.status === 'payment' ) {
                                window.location.replace( response.message._links.checkout );
                            }

                            if ( response.status === 'subscription_added' ) {
                                alert( 'Abonnement toegevoegd!' );
                                location.reload();
                            }
                        }
                    } );
                } );


                $( '[data-action="cancelSubscription"]' ).on( 'click', function ( e ) {
                    e.preventDefault();
                    var subscription_id = $( this ).data( 'sid' );

                    $.ajax( {
                        url: gwo.ajax_url,
                        type: 'POST',
                        dataType: 'json',
                        data: {
                            action: 'gwo_cancel_subscription',
                            subscription_id: subscription_id
                        },
                        success: function ( response ) {
                            if ( response.status === 'cancelled' ) {
                                alert( 'Abonnement geannuleerd!' );
                                location.reload();
                            }
                        }
                    } );

                    return false;
                } );
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function ( func, funcname, args ) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if ( fire ) {
                namespace[func][funcname]( args );
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire( 'common' );

            // Fire page-specific init JS, and then finalize JS
            $.each( document.body.className.replace( /-/g, '_' ).split( /\s+/ ), function ( i, classnm ) {
                UTIL.fire( classnm );
                UTIL.fire( classnm, 'finalize' );
            } );

            // Fire common finalize JS
            UTIL.fire( 'common', 'finalize' );
        }
    };

    // Load Events
    $( document ).ready( UTIL.loadEvents );

})( jQuery ); // Fully reference jQuery after this point.
